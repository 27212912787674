import { Toolbar } from "@mui/material";
import { Fragment } from "react";
import ToResolveClassNameError from "../../components/hoc/ToResolveClassNameError";
import BacklinkComponent from "../../components/jobs/FooterWriteup/FooterLinks";
import HiredlyWriteup from "../../components/jobs/FooterWriteup/HiredlyWriteup";
import JobListSubheader from "../../components/jobs/JobListSubheader/JobListSubheader";
import JobListWrapper from "../../components/jobs/JobListWrapper/JobListWrapper";
import SpecialisationWriteup from "../../components/jobs/SpecialisationWriteup/SpecialisationWriteup";
import SharedChat from "../../components/shared/SharedChat/SharedChat";
import SharedCollapsibleFooter from "../../components/shared/SharedCollapsibleFooter/SharedCollapsibleFooter";
import SharedJobListHeader from "../../components/shared/SharedHeader/SharedJobListHeader/ShareJobListHeader";
import { formJobQueryParam } from "../../functions/job";
import {
  check404,
  convertUrlToIds,
  encodePage,
  getSelectedTab,
  getTitle,
} from "../../helpers/filter_management";
import {
  getCmsLocations,
  getCmsSpecialisations,
  getFooterCompanyWriteup,
  getJobsWithoutRedux,
  getSpecialisationWithoutRedux,
  getStateRegionWithoutRedux,
} from "../../redux/actions/job_action";

function JobFiltersPage(props) {
  const {
    jobs,
    ssrFilterParams,
    ssrPageParams,
    currentTab,
    titleInfo,
    specialisationList,
  } = props;

  return (
    <Fragment>
      <SharedJobListHeader
        jobs={jobs}
        ssrFilterParams={ssrFilterParams}
        specialisationList={specialisationList}
      />

      <ToResolveClassNameError>
        <Toolbar
          sx={{
            minHeight: "64px",
            "@media(max-width: 1279px)": {
              minHeight: "52px",
            },
            "@media(max-width: 640px)": {
              minHeight: "60px",
            },
          }}
        />
      </ToResolveClassNameError>
      <JobListSubheader titleInfo={titleInfo ?? null} />
      <JobListWrapper
        serverJobs={jobs}
        ssrFilterParams={ssrFilterParams}
        ssrPageParams={ssrPageParams}
        currentTab={currentTab}
      />

      {currentTab === "job-list" && (
        <>
          <BacklinkComponent
            cmsSpecialisations={props.cmsSpecialisations}
            cmsLocations={props.cmsLocations}
          />
          <SpecialisationWriteup specialisation={props.specialisation} />
          <HiredlyWriteup companyWriteup={props.companyWriteup} />
        </>
      )}
      <SharedCollapsibleFooter />
      {/* SSR component END */}
      <ToResolveClassNameError>
        <SharedChat />
      </ToResolveClassNameError>
    </Fragment>
  );
}

export async function getServerSideProps(context) {
  const { query, req } = context;

  const pageExist = check404(query?.filter);

  if (pageExist) {
    return {
      notFound: true,
    };
  }

  const specialisationList = await getSpecialisationWithoutRedux();
  const stateList = await getStateRegionWithoutRedux();

  const filterParams = convertUrlToIds(query, specialisationList, stateList);

  const currentPage = encodePage(query?.page);

  const currentTab = getSelectedTab(
    filterParams,
    currentPage,
    req.cookies.defaultFyp
  );

  const jobParams = formJobQueryParam(filterParams, false);

  const pagination = {
    first: 30,
    last: null,
    startCursor: currentPage ?? null,
    endCursor: null,
  };

  const jobs = await getJobsWithoutRedux(jobParams, pagination);
  const titleInfo = getTitle(filterParams, specialisationList);

  let cmsSpecialisations = [];
  let cmsLocations = [];
  let companyWriteup = {};

  if (currentTab === "job-list") {
    cmsSpecialisations = await getCmsSpecialisations();
    cmsLocations = await getCmsLocations();
    companyWriteup = await getFooterCompanyWriteup();
  }

  return {
    props: {
      currentTab: currentTab,
      jobs: jobs?.jobs ?? [],
      ssrFilterParams: filterParams,
      ssrPageParams: currentPage ?? null,
      titleInfo: titleInfo,
      cmsSpecialisations: cmsSpecialisations ?? [],
      cmsLocations: cmsLocations ?? [],
      companyWriteup: companyWriteup ?? {},
      specialisationList: specialisationList ?? [],
    },
  };
}

export default JobFiltersPage;
