import { useIsClient } from "@uidotdev/usehooks";
import Head from "next/head";
import { usePathname, useSearchParams } from "next/navigation";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as config from "../../../../config/config";
import { jobTypes } from "../../../../helpers/constant";
import { currentMonthYear } from "../../../../helpers/meta_date";

// Set up helper functions to format locations
const getLocationString = (state) => {
  if (!state?.length) return "";

  return state.length > 1
    ? `${state[0]} & ${state.length - 1} other state${
        state.length > 2 ? "s" : ""
      }`
    : state[0];
};

// Filter keywords that have hyphen "-" and capitalize first letter of each word
const filterKeywords = (keywords) => {
  let filteredKeywords = keywords
    ?.replace(/-/g, " ")
    ?.split(" ")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    ?.join(" ");

  return filteredKeywords;
};

// Filter job types from array
const filterJobTypes = (jobType) => {
  const typeMap = jobTypes.reduce((map, type) => {
    // replace hyphen from jobType constant with space
    map[type.id] = type.jobType.replace("-", " ");
    return map;
  }, {});

  const filteredTypes = jobType.map((id) => typeMap[id]).filter(Boolean);

  return filteredTypes.length > 1
    ? `${filteredTypes[0]} & ${filteredTypes.length - 1} more job type${
        filteredTypes.length > 2 ? "s" : ""
      }`
    : filteredTypes[0];
};

const filterSpecialisations = (specialisations, jobSpecialisations) => {
  // Group selected subspecialisations by their parent specialisation
  const groupedByParent = new Map();

  // First pass: Group subspecialisations by their parent specialisation
  jobSpecialisations.forEach((jobSpecialisation) => {
    // Early object destructuring
    const { id, name, subSpecialisations } = jobSpecialisation;
    const selectedSubSpecialisations = subSpecialisations.filter(
      (subSpecialisation) => specialisations.includes(subSpecialisation.id)
    );

    if (selectedSubSpecialisations.length > 0) {
      // Add grouped subspecialisations to map function
      groupedByParent.set(id, {
        parentSpecialisationName: name,
        selected: selectedSubSpecialisations,
        total: subSpecialisations.length,
      });
    }
  });

  // Convert grouped subspecialisations to string
  const filteredSpecialisations = [];
  groupedByParent.forEach(({ parentSpecialisationName, selected, total }) => {
    if (selected.length === total) {
      filteredSpecialisations.push(parentSpecialisationName);
    } else {
      selected.forEach((specialisation) =>
        filteredSpecialisations.push(specialisation.name)
      );
    }
  });

  // Format output
  return filteredSpecialisations.length > 1
    ? `${filteredSpecialisations[0]} & ${
        filteredSpecialisations.length - 1
      } more classification`
    : filteredSpecialisations[0];
};

// meta tags generation function
const generateMetaTags = (filters, specialisationList, stateList) => {
  const defaultMeta = {
    title: `New Job Vacancies in Malaysia - ${currentMonthYear} | Hiredly`,
    description: `New jobs for you to apply. Find full time, internship and work from home jobs available in Malaysia. Apply now at Hiredly!`,
  };

  const specFilter =
    filters?.specialisation && filters?.specialisation.length > 0;
  const stateFilter = filters?.state && filters?.state.length > 0;
  const jobTypeFilter = filters?.jobType && filters?.jobType.length > 0;
  const keywordFilter = filters?.keyword;

  if (!specFilter && !stateFilter && !jobTypeFilter && !keywordFilter) {
    return defaultMeta;
  }

  const keyword = filters?.keyword
    ? filterKeywords(
        Array.isArray(filters.keyword) ? filters.keyword[0] : filters.keyword
      )
    : null;

  const jobType = filters?.jobType ? filterJobTypes(filters.jobType) : null;
  const specialisation = filters?.specialisation
    ? filterSpecialisations(filters.specialisation, specialisationList)
    : null;
  const location = filters?.state
    ? getLocationString(filters.state, stateList)
    : null;

  // Create an array of object of meta templates to match with condition filters
  const metaTemplates = [
    {
      // Handle multiple filters (4 filters)
      // Filter jobs by keyword, specialization, location and job type
      condition: keyword && specialisation && location && jobType,
      title: `${keyword} ${jobType} in ${specialisation} Job Vacancies in ${location} - ${currentMonthYear} | Hiredly`,
      description: `Find the latest available ${keyword} ${jobType} ${specialisation} job vacancies in ${location} available on Hiredly. Apply now.`,
    },
    {
      // Handle multiple filters (3 filters)
      // Filter jobs by keyword, location and specialisation
      condition: keyword && location && specialisation,
      title: `${keyword} in ${specialisation} Job Vacancies in ${location} - ${currentMonthYear} | Hiredly`,
      description: `Find the latest available ${keyword} ${specialisation} job vacancies in ${location} available on Hiredly. Apply now.`,
    },
    {
      // Filter jobs by keyword, job type and specialisation
      condition: keyword && jobType && specialisation,
      title: `${keyword} ${jobType} in ${specialisation} Job Vacancies in Malaysia - ${currentMonthYear} | Hiredly`,
      description: `Find the latest available ${keyword} ${jobType} ${specialisation} job vacancies in Malaysia available on Hiredly. Apply now.`,
    },
    {
      // Filter jobs by keyword, location and job type
      condition: keyword && location && jobType,
      title: `${keyword} ${jobType} Job Vacancies in ${location} - ${currentMonthYear} | Hiredly`,
      description: `Find the latest available ${keyword} ${jobType} job vacancies in ${location} available on Hiredly. Apply now.`,
    },
    {
      // Filter jobs by location, job type and specialisation
      condition: location && jobType && specialisation,
      title: `${jobType} ${specialisation} Job Vacancies in ${location} - ${currentMonthYear} | Hiredly`,
      description: `Find the latest ${jobType} job vacancies within ${specialisation} in ${location} available on Hiredly. Apply now.`,
    },
    {
      // Handle multiple filters (2 filters)
      condition: keyword && specialisation,
      title: `${keyword} in ${specialisation} Job Vacancies in Malaysia - ${currentMonthYear} | Hiredly`,
      description: `Find the latest available ${keyword} ${specialisation} job vacancies in Malaysia available on Hiredly. Apply now.`,
    },
    {
      condition: keyword && location,
      title: `${keyword} Job Vacancies in ${location} - ${currentMonthYear} | Hiredly`,
      description: `Find the latest available ${keyword} job vacancies in ${location} available on Hiredly. Apply now.`,
    },
    {
      condition: keyword && jobType,
      title: `${keyword} ${jobType} Job Vacancies in Malaysia - ${currentMonthYear} | Hiredly`,
      description: `Find the latest available ${keyword} ${jobType} job vacancies in Malaysia available on Hiredly. Apply now.`,
    },
    {
      condition: specialisation && location,
      title: `${specialisation} Job Vacancies in ${location} - ${currentMonthYear} | Hiredly`,
      description: `Find the latest ${specialisation} job vacancies in ${location} available on Hiredly. Apply now.`,
    },
    {
      condition: specialisation && jobType,
      title: `${jobType} ${specialisation} Job Vacancies in Malaysia - ${currentMonthYear} | Hiredly`,
      description: `Find the latest ${jobType} job vacancies within ${specialisation} in Malaysia available on Hiredly. Apply now.`,
    },
    {
      condition: location && jobType,
      title: `${jobType} Job Vacancies in ${location} - ${currentMonthYear} | Hiredly`,
      description: `Find the latest ${jobType} job vacancies in ${location} available on Hiredly. Apply now.`,
    },
    {
      // Handle single filter
      condition: keyword,
      title: `${keyword} Job Vacancies in Malaysia - ${currentMonthYear} | Hiredly`,
      description: `Find your next ${keyword} job in Malaysia available on Hiredly. Apply now.`,
    },
    {
      condition: specialisation,
      title: `${specialisation} Job Vacancies in Malaysia - ${currentMonthYear} | Hiredly`,
      description: `Find the latest available ${specialisation} job vacancies in Malaysia available on Hiredly. Apply now.`,
    },
    {
      condition: jobType,
      title: `${jobType} Job Vacancies in Malaysia - ${currentMonthYear} | Hiredly`,
      description: `Find the latest available ${jobType} job vacancies in Malaysia available on Hiredly. Apply now.`,
    },
    {
      condition: location,
      title: `Job Vacancies in ${location} - ${currentMonthYear} | Hiredly`,
      description: `Find the latest available job vacancies in ${location} available on Hiredly. Apply now.`,
    },
  ];

  // Find the first matching template
  const matchedTemplate = metaTemplates.find((template) => {
    return template.condition;
  });

  return matchedTemplate || defaultMeta;
};

function SharedJobListHeader({ ssrFilterParams, specialisationList, jobs }) {
  const jobsParams = useSelector((state) => state.jobs?.jobListFilter);
  const stateList = useSelector((state) => state.jobs?.stateRegions);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const isClient = useIsClient();

  // Track both SSR and client-side filter params
  const [currentFilter, setCurrentFilter] = useState(ssrFilterParams);

  useEffect(() => {
    if (isClient) {
      setCurrentFilter(jobsParams);
    }
  }, [isClient]);

  useEffect(() => {
    setCurrentFilter(jobsParams);
  }, [jobsParams]);

  // generate meta tags
  const { title: metaTitle, description: metaDescription } = generateMetaTags(
    currentFilter,
    specialisationList,
    stateList
  );

  let search = new URLSearchParams(searchParams);
  // remove filter from search params
  search.delete("filter");

  let metaIndex = false;
  let canonicalUrl = "";

  if (pathname.includes("jobs")) {
    if (search.toString() === "") {
      canonicalUrl = `https://my.hiredly.com${pathname}`;
    } else {
      canonicalUrl = `https://my.hiredly.com${pathname}?${search.toString()}`;
    }
  } else {
    canonicalUrl = "https://my.hiredly.com/jobs";
  }

  if (jobs.length === 0) {
    metaIndex = true;
  }

  return (
    <Fragment>
      <Head>
        {/* Primary Meta Tags */}
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        {metaIndex && <meta name="robots" content="noindex, nofollow" />}

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" key={"ogType"} />
        <meta property="og:url" content={canonicalUrl} key="ogUrl" />
        <meta property="og:title" content={metaTitle} key="ogTitle" />
        <meta
          property="og:description"
          content={metaDescription}
          key="ogDescription"
        />
        <meta
          property="og:image"
          content={
            config.assetDomain + "/images/hiredly/hiredly-meta-image-v1.png"
          }
          key="ogImage"
        />

        {/* Twitter */}
        <meta
          property="twitter:card"
          content="summary_large_image"
          key="twitterCard"
        />
        <meta property="twitter:url" content={canonicalUrl} key="twitterUrl" />
        <meta property="twitter:title" content={metaTitle} key="twitterTitle" />
        <meta
          property="twitter:description"
          content={metaDescription}
          key="twitterDescription"
        />
        <meta
          property="twitter:image"
          content={
            config.assetDomain + "/images/hiredly/hiredly-meta-image-v1.png"
          }
          key="twitterImage"
        />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />

        {/* Canonical URL */}
        <link rel="canonical" href={canonicalUrl} />
      </Head>
    </Fragment>
  );
}

export default SharedJobListHeader;
